import { useState } from "react";
import { db } from "../firebase";
import {
    addDoc,
    collection,
    serverTimestamp,
} from "firebase/firestore";
import {
    message,
} from "antd";
import jsonp from 'jsonp';
import "../styles/css/navbar.css"
import "../styles/css/home.css"
import { NavLink, useNavigate } from "react-router-dom";
import { FiMenu } from "react-icons/fi";
import { RiCloseLine } from "react-icons/ri";
import logo from "../styles/images/logo.png";
import { BiDonateHeart } from "react-icons/bi";
import { LiaHandsHelpingSolid } from "react-icons/lia";
import { CiGlobe } from "react-icons/ci";
import { GiSelfLove, GiSquareBottle } from "react-icons/gi";
import { PiBowlFoodLight } from "react-icons/pi";
import { TbMedicalCross } from "react-icons/tb";
import { FaFacebookF, FaTwitter, FaInstagram } from "react-icons/fa";

const Home = () => {
    const [messageApi, contextHolder] = message.useMessage();
    const [clicked, setClicked] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [_email, _setEmail] = useState('');
    const [phone, setPhone] = useState("");
    const [address, setAddress] = useState("");
    const [occupation, setOccupation] = useState("");
    const [msg, setMsg] = useState("");
    const navigate = useNavigate();
    const handleClick = () => {
        setClicked(!clicked);
    };
    const handleSubmit = async e => {
        e.preventDefault();
        if (name === "" || email === "" || phone === "" || address === "" || msg === "" || occupation === "") {
            messageApi.open({
                type: "error",
                content: "All fields are required",
            });
        } else {
            try {
                const docRef = await addDoc(collection(db, "responses"), {
                    name: name,
                    email: phone,
                    phone: phone,
                    address: address,
                    occupation: occupation,
                    message: msg,
                    added: serverTimestamp(),
                }).then(() => messageApi.open({
                    type: "success",
                    content: "Form Submitted",
                }))
                window.location.reload(false)
            } catch (e) {
                messageApi.open({
                    type: "error",
                    content: e.message,
                });
            }
        }
    }
    const onSubmit = e => {
        e.preventDefault();
        const url = 'https://yahoo.us21.list-manage.com/subscribe/post?u=09d4351f36feb4741ca1f5b3e&amp;id=8a8d85b1e2&amp;f_id=0084f4e6f0';
        jsonp(`${url}&EMAIL=${_email}`, { param: 'c' }, (_, data) => {
            // const { msg, result } = data
            // do something with response
        });
        messageApi.open({
            type: "success",
            content: "Response successfull",
        });
        e.target.reset();
    };
    return (
        <>
            <nav className="navbar">
                {contextHolder}
                <div className={clicked ? "container active" : "container"}>
                    <div onClick={() => navigate("/")} className="logo">
                        <img src={logo} alt="" />
                    </div>
                    <div className="nav-elements">
                        <ul>
                            <li>
                                <a href="#main">Home</a>
                            </li>
                            <li>
                                <NavLink to="/about-us">About Us</NavLink>
                            </li>
                            <li>
                                <a href="#volunteer">Become A Volunteer</a>
                            </li>
                            <li>
                                <NavLink to="/contact">Contact</NavLink>
                            </li>
                        </ul>
                    </div>
                    <div className="donate-button">
                        <a href="https://www.paypal.com/donate/?hosted_button_id=PC8DFDXZ2SS7S">
                            <button>Donate now</button>
                        </a>
                    </div>
                </div>
                <div className="donate-button2">
                    <a href="https://www.paypal.com/donate/?hosted_button_id=PC8DFDXZ2SS7S">
                            <button>Donate now</button>
                    </a>
                </div>
                <div onClickCapture={handleClick} className="menu">
                    {clicked ? <RiCloseLine /> : <FiMenu />}
                </div>
            </nav>
            <section className="main" id="main">
                <div className="main-details">
                    <h1>welcome to Timbuktu Bamba </h1>
                    <h2>Lend the helping hand get involved</h2>
                    <a href="/about-us"><button>read more</button></a>
                </div>
            </section>
            <section className="main2">
                <div className="main2-row1">
                    <div className="details">
                        <h1>Hunger is stalking the globe</h1>
                    </div>
                </div>
                <div className="main2-row2">
                    <div className="main2-r2-img">
                        <img src="https://images.unsplash.com/flagged/photo-1555251255-e9a095d6eb9d?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="" />
                    </div>
                    <div className="main2-r2-col">
                        <h1> Your donation fuels positive change and empowers futures.</h1>
                        <h2>Join our cause and take care of everyone</h2>
                    </div>
                </div>
            </section>
            <section className="services">
                <div className="s-card1">
                    <div className="details1">

                    </div>
                    <div className="details2">
                        <h1>Empowering people</h1>
                        <div className="button-row1">
                            <a href="/contact"><button>read more</button></a>
                        </div>
                    </div>
                </div>
                <div className="s-card2">
                    <div className="details1">

                    </div>
                    <div className="details2">
                        <h1>Become a Volunteer</h1>
                        <div className="button-row2">
                            <a href="/contact"><button>read more</button></a>
                        </div>
                    </div>
                </div>
                <div className="s-card3">
                    <div className="details1">

                    </div>
                    <div className="details2">
                        <h1>Let's make an impact</h1>
                        <div className="button-row2">
                            <a href="/contact"><button>read more</button></a>
                        </div>
                    </div>
                </div>
            </section>
            <section className="donation">
                <div className="donation-details">
                    <h1>Dedicated to enhancing our beneficiaries lives </h1>
                    <h2>help us now</h2>
                </div>
                <div className="donation-row">
                    <div className="donation-card">
                        <div className="inner1">
                            <BiDonateHeart className="icon" />
                        </div>
                        <h1>Donation</h1>
                        <p>Kindly donate to aid impoverished individuals in need of essential support today.</p>
                    </div>
                    <div className="donation-card">
                        <div className="inner2">
                            <LiaHandsHelpingSolid className="icon" />
                        </div>
                        <h1>Volunteering</h1>
                        <p>Volunteers needed to support the less fortunate. Make a positive impact today.</p>
                    </div>
                    <div className="donation-card">
                        <div className="inner3">
                            <CiGlobe className="icon" />
                        </div>
                        <h1>Peace</h1>
                        <p>Embrace harmony, uniting hearts, and fostering a global understanding together.</p>
                    </div>
                    <div className="donation-card">
                        <div className="inner4">
                            <GiSelfLove className="icon" />
                        </div>
                        <h1>Love</h1>
                        <p>Embrace love's power: a transformative force that enriches lives with endless warmth.</p>
                    </div>
                </div>
                <a href="/contact"><button>donate now</button></a>
            </section>
            <section className="phrase">
                <h1>Our echoes in the lives we touch never fade.</h1>
                <a href="/about-us"> <button>read more</button></a>
            </section>
            <form onSubmit={handleSubmit}>
                <section className="volunteer" id="volunteer">
                    <div className="volunteer-row1">
                        <div className="v-col1">
                            <h1>Give a helping hand for homeless childrens</h1>
                        </div>
                        <div className="v-col2">
                            <p>Extend a compassionate hand to homeless children, offering hope and support. Together, we can make a difference in their lives, ensuring brighter futures. Your kindness provides shelter, education, and a chance for a better tomorrow. Join us in creating a world where every child has the opportunity to thrive and succeed.</p>
                        </div>
                    </div>
                    <div className="volunteer-row2">
                        <div className="v-col-1">
                            <div className="v-card1">
                                <PiBowlFoodLight className="v-icon" />
                                <h1>Food</h1>
                            </div>
                            <div className="v-card2">
                                <GiSquareBottle className="v-icon" />
                                <h1>Water</h1>
                            </div>
                            <div className="v-card3">
                                <TbMedicalCross className="v-icon" />
                                <h1>Medical</h1>
                            </div>
                        </div>
                        <div className="v-col-2">
                            <h1>Become A Volunteer</h1>
                            <h2>join us now</h2>
                            <input type="text" placeholder="Full Name" name="Full Name" value={name} onChange={(e) => setName(e.target.value)} />
                        </div>
                    </div>
                </section>
                <section className="volunteer-2">
                    <div className="v2-col-1">
                        <div className="v2-img">
                            <img src="https://images.unsplash.com/photo-1547496614-54ff387d650a?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="" />
                        </div>
                        <h1>Charity activities are taken place around the world</h1>
                        <h2>DONATE US NOW</h2>
                        <p>Across the globe, a multitude of charity initiatives tirelessly uplift lives, providing hope and essential support to those in need. Acts of kindness unite communities, fostering positive change and spreading compassion worldwide.</p>
                    </div>
                    <div className="v2-col-2">
                        <input type="text" placeholder="Email Address" name="email" value={email}
                            onChange={(e) => setEmail(e.target.value)} />
                        <input type="text" placeholder="Phone Number" name="phone" value={phone}
                            onChange={(e) => setPhone(e.target.value)} />
                        <input type="text" placeholder="Address" name="address" value={address}
                            onChange={(e) => setAddress(e.target.value)} />
                        <input type="text" placeholder="Occupation" name="occupation" value={occupation}
                            onChange={(e) => setOccupation(e.target.value)} />
                        <textarea rows="10" cols="80" type="text" name="message" placeholder="Message" value={msg}
                            onChange={(e) => setMsg(e.target.value)} />
                        <div className="s">
                            <input type="submit" value="send" className="btn" />
                        </div>
                    </div>
                </section>
            </form>
            <section className="volunteer-mobile">
                <div className="volunteer-m-row1">
                    <div className="v-mr-col1">
                        <h1>Give a helping hand for homeless childrens</h1>
                    </div>
                    <div className="v-mr-col2">
                        <p>Extend a compassionate hand to homeless children, offering hope and support. Together, we can make a difference in their lives, ensuring brighter futures. Your kindness provides shelter, education, and a chance for a better tomorrow. Join us in creating a world where every child has the opportunity to thrive and succeed.</p>
                    </div>
                </div>
                <div className="volunteer-m-row2">
                    <div className="v-m-card1">
                        <PiBowlFoodLight className="m-icon" />
                        <h1>Food</h1>
                    </div>
                    <div className="v-m-card2">
                        <GiSquareBottle className="m-icon" />
                        <h1>Water</h1>
                    </div>
                    <div className="v-m-card3">
                        <TbMedicalCross className="m-icon" />
                        <h1>Medical</h1>
                    </div>
                </div>
            </section>
            <section className="volunteer-mobile2">
                <div className="v-m2-image">
                    <img src="https://images.unsplash.com/photo-1547496614-54ff387d650a?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="" />
                </div>
                <h1>Charity activities are taken place around the world</h1>
                <h2>DONATE US NOW</h2>
                <p>Across the globe, a multitude of charity initiatives tirelessly uplift lives, providing hope and essential support to those in need. Acts of kindness unite communities, fostering positive change and spreading compassion worldwide.</p>
                <div className="v-mobile-form">
                    <h1>Become A Volunteer</h1>
                    <h2>join us now</h2>
                    <form onSubmit={handleSubmit}>
                        <input type="text" placeholder="Full Name" onChange={(e) => setName(e.target.value)} value={name} />
                        <input type="text" placeholder="Email Address" onChange={(e) => setEmail(e.target.value)} value={email} />
                        <input type="text" placeholder="Phone Number" onChange={(e) => setPhone(e.target.value)} value={phone} />
                        <input type="text" placeholder="Address" onChange={(e) => setAddress(e.target.value)} value={address} />
                        <input type="text" placeholder="Occupation" onChange={(e) => setOccupation(e.target.value)} value={occupation} />
                        <textarea rows="10" cols="80" type="text" name="message" placeholder="Message" onChange={(e) => setMsg(e.target.value)} value={msg} />
                        <div className="s">
                            <input type="submit" value="send" className="btn" />
                        </div>
                    </form>
                </div>
            </section>
            <section className="spacer">

            </section>
            <form onSubmit={onSubmit}>
            <section className="newsletter">
                <h1>Newsletter</h1>
                <h2>Stay updated</h2>
                <div className="input-wrap">
                <input type="email"
                            placeholder="Enter E-Mail Address"
                            name="EMAIL"
                            required
                            onChange={(e) => _setEmail(e.target.value)} />
                        <button type="submit">
                            subscribe
                        </button>
                </div>
            </section>
            </form>
            <footer>
                <div className="footer-row">
                    <div className="footer-col">
                        <h1>Pages</h1>
                        <h2><a href="">Home</a></h2>
                        <h2><a href="">About</a></h2>
                        <h2><a href="">Become a volunteer</a></h2>
                        <h2><a href="">Contact</a></h2>
                        <h2><a href="">Donate</a></h2>
                    </div>
                    <div className="footer-col">
                        <h1>Links</h1>
                        <h2><a href="">Services</a></h2>
                        <h2><a href="">Donate</a></h2>
                        <h2><a href="">Newsletter</a></h2>
                        <h2><a href="">About</a></h2>

                    </div>
                    <div className="footer-col">
                        <h1>Explore</h1>
                        <h2><a href="">Home</a></h2>
                        <h2><a href="">About</a></h2>
                        <h2><a href="">Become a volunteer</a></h2>
                        <h2><a href="">Contact</a></h2>
                        <h2><a href="">Donate</a></h2>
                    </div>
                    <div className="footer-col">
                        <h1>Contact</h1>
                        <h2>1385 Lucile Avenue SE, Marietta GA 30067</h2>
                        <h2>info@timbuktubamba.org</h2>
                        <h2>404-519-0340</h2>
                        <div className="footer-col-row">
                            <a href="https://www.facebook.com/timbuktubambaorg/" target="_blank" rel="noopener noreferrer">
                            <div className="footer-card1">
                                <FaFacebookF className="f-icon" />
                            </div>
                            </a>
                            <a href="https://www.instagram.com/timbuktubambaorg/" target="_blank" rel="noopener noreferrer">
                            <div className="footer-card3">
                                <FaInstagram className="f-icon" />

                            </div>
                            </a>
                            <a href="https://twitter.com/timbukbambaOrg" target="_blank" rel="noopener noreferrer">
                            <div className="footer-card3">
                                <FaTwitter className="f-icon" />

                            </div>
                            </a>
                        </div>
                    </div>
                </div>
                <hr />
                <h5>Copyright - 2023 - Timbuktu Bamba - All rights reserved. Designed by Mozman.</h5>
            </footer>
        </>
    );
}

export default Home;