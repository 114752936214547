import "../styles/css/navbar.css"
import "../styles/css/home.css"
import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { FiMenu } from "react-icons/fi";
import { RiCloseLine } from "react-icons/ri";
import logo from "../styles/images/logo.png"
import "../styles/css/about.css"
import { BiDonateHeart } from "react-icons/bi";
import { LiaHandsHelpingSolid } from "react-icons/lia";
import { CiGlobe } from "react-icons/ci";
import { GiSelfLove } from "react-icons/gi";
import { FaFacebookF, FaTwitter, FaInstagram } from "react-icons/fa";
import { HashLink } from "react-router-hash-link";
import {
    message,
} from "antd";
import jsonp from 'jsonp';



const About = () => {
    const [messageApi, contextHolder] = message.useMessage();
    const [email, setEmail] = useState("");
    const [clicked, setClicked] = useState(false);
    const navigate = useNavigate();
    const handleClick = () => {
        setClicked(!clicked);
    };
    const onSubmit = e => {
        e.preventDefault();
        const url = 'https://yahoo.us21.list-manage.com/subscribe/post?u=09d4351f36feb4741ca1f5b3e&amp;id=8a8d85b1e2&amp;f_id=0084f4e6f0';
        jsonp(`${url}&EMAIL=${email}`, { param: 'c' }, (_, data) => {
            // const { msg, result } = data
            // do something with response
        });
        messageApi.open({
            type: "success",
            content: "Response successfull",
        });
        e.target.reset();
    };
    return (
        <>
            <nav className="navbar">
                {contextHolder}
                <div className={clicked ? "container active" : "container"}>
                    <div onClick={() => navigate("/")} className="logo">
                        <img src={logo} alt="" />
                    </div>
                    <div className="nav-elements">
                        <ul>
                            <ul>
                                <li>
                                    <HashLink to="/#">Home</HashLink>
                                </li>
                                <li>
                                    <NavLink to="/about-us">About Us</NavLink>
                                </li>
                                <li>
                                    <HashLink to="/#volunteer">Become A Volunteer</HashLink>
                                </li>
                                <li>
                                    <NavLink to="/contact">Contact</NavLink>
                                </li>
                            </ul>
                        </ul>
                    </div>
                    <div className="donate-button">
                        <a href="https://www.paypal.com/donate/?hosted_button_id=PC8DFDXZ2SS7S">
                            <button>Donate now</button>
                        </a>                    
                    </div>
                </div>
                <div className="donate-button2">
                    <a href="https://www.paypal.com/donate/?hosted_button_id=PC8DFDXZ2SS7S">
                        <button>Donate now</button>
                    </a>
                </div>
                <div onClickCapture={handleClick} className="menu">
                    {clicked ? <RiCloseLine /> : <FiMenu />}
                </div>
            </nav>
            <section className="about-intro">
                <h1>About Page</h1>
            </section>
            <section className="about-header">
                <div className="image">
                    <img src="https://images.unsplash.com/photo-1567057419565-4349c49d8a04?q=80&w=1472&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="" />
                </div>
            </section>
            <section className="about-main">
                <div className="about-main-image">
                    <img src="https://images.unsplash.com/photo-1511166698451-3e4e3438d1ee?q=80&w=1374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="" />
                </div>
                <div className="about-main-col">
                    <h1>About Timbuktu Bamba</h1>
                    <h2>LEARN ABOUT THE NON PROFIT ORGANIZATIOn</h2>
                    <p className="one">We are a nonprofit organization committed to helping individuals and families in communities with real life challenges. We organize and support outreach activities nationwide. We are engaged in any other activities in line with our purpose. We will be actively involved in social Service program by providing free food and good distribution, free youth mentorship, support poor people and communities by supporting them with donations</p>
                    <p className="two">Funding will come from donations by individuals, corporations, charities, private investors, and the government, thereby providing essential support and training</p>
                </div>
            </section>
            <section className="donation">
                <div className="donation-details">
                    <h1>Dedicated to enhancing our beneficiaries lives </h1>
                    <h2>help us now</h2>
                </div>
                <div className="donation-row">
                    <div className="donation-card">
                        <div className="inner1">
                            <BiDonateHeart className="icon" />
                        </div>
                        <h1>Donation</h1>
                        <p>Kindly donate to aid impoverished individuals in need of essential support today.</p>
                    </div>
                    <div className="donation-card">
                        <div className="inner2">
                            <LiaHandsHelpingSolid className="icon" />
                        </div>
                        <h1>Volunteering</h1>
                        <p>Volunteers needed to support the less fortunate. Make a positive impact today.</p>
                    </div>
                    <div className="donation-card">
                        <div className="inner3">
                            <CiGlobe className="icon" />
                        </div>
                        <h1>Peace</h1>
                        <p>Embrace harmony, uniting hearts, and fostering a global understanding together.</p>
                    </div>
                    <div className="donation-card">
                        <div className="inner4">
                            <GiSelfLove className="icon" />
                        </div>
                        <h1>Love</h1>
                        <p>Embrace love's power: a transformative force that enriches lives with endless warmth.</p>
                    </div>
                </div>
                <a href="/contact"><button>donate now</button></a>
            </section>
            <form onSubmit={onSubmit}>
                <section className="newsletter">
                    <h1>Newsletter</h1>
                    <h2>Stay updated</h2>
                    <div className="input-wrap">
                        <input type="email"
                            placeholder="Enter E-Mail Address"
                            name="EMAIL"
                            required
                            onChange={(e) => setEmail(e.target.value)} />
                        <button type="submit">
                            subscribe
                        </button>
                    </div>
                </section>
            </form>
            <footer>
                <div className="footer-row">
                    <div className="footer-col">
                        <h1>Pages</h1>
                        <h2><a href="">Home</a></h2>
                        <h2><a href="">About</a></h2>
                        <h2><a href="">Become a volunteer</a></h2>
                        <h2><a href="">Contact</a></h2>
                        <h2><a href="">Donate</a></h2>
                    </div>
                    <div className="footer-col">
                        <h1>Links</h1>
                        <h2><a href="">Services</a></h2>
                        <h2><a href="">Donate</a></h2>
                        <h2><a href="">Newsletter</a></h2>
                        <h2><a href="">About</a></h2>

                    </div>
                    <div className="footer-col">
                        <h1>Explore</h1>
                        <h2><a href="">Home</a></h2>
                        <h2><a href="">About</a></h2>
                        <h2><a href="">Become a volunteer</a></h2>
                        <h2><a href="">Contact</a></h2>
                        <h2><a href="">Donate</a></h2>
                    </div>
                    <div className="footer-col">
                        <h1>Contact</h1>
                        <h2>1385 Lucile Avenue SE, Marietta GA 30067</h2>
                        <h2>info@timbuktubamba.org</h2>
                        <h2>404-519-0340</h2>
                        <div className="footer-col-row">
                            <a href="https://www.facebook.com/timbuktubambaorg/" target="_blank" rel="noopener noreferrer">
                            <div className="footer-card1">
                                <FaFacebookF className="f-icon" />
                            </div>
                            </a>
                            <a href="https://www.instagram.com/timbuktubambaorg/" target="_blank" rel="noopener noreferrer">
                            <div className="footer-card3">
                                <FaInstagram className="f-icon" />

                            </div>
                            </a>
                            <a href="https://twitter.com/timbukbambaOrg" target="_blank" rel="noopener noreferrer">
                            <div className="footer-card3">
                                <FaTwitter className="f-icon" />

                            </div>
                            </a>
                        </div>
                    </div>
                </div>
                <hr />
                <h5>Copyright - 2023 - Timbuktu Bamba - All rights reserved. Designed by Mozman.</h5>
            </footer>
        </>
    );
}

export default About;