import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useState, useEffect } from "react";
import {
    collection,
    query,
    getDocs,
    orderBy,
} from "firebase/firestore";
import { db } from "../firebase";
import "../styles/css/dashboard.css";
import SyncLoader from "react-spinners/SyncLoader";

const Responses = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);

    const getResponses = async () => {
        const responseRef = collection(db, "responses");
        const responseQuery = query(responseRef, orderBy("added"));
        const querySnapshots = await getDocs(responseQuery);
        let data = [];
        querySnapshots.forEach((doc) => {
            data.push({ id: doc.id, ...doc.data() });
        });
        setRows(data);
        setLoading(false);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    useEffect(() => {
        getResponses();
        return () => {
            getResponses();
        };
    }, []);
    if (loading) {
        return (
            <div className="spinner">
                <SyncLoader color={"#1e2e3e"} loading={loading} size={10} />
            </div>
        );
    }

    return (
        <div className="dashboard">
            <h1>Responses</h1>
            <div className="table">
                {rows.length > 0 && (
                    <Paper sx={{ width: "98%", overflow: "hidden", padding: "12px" }}>
                        <Box height={10} />
                        <Stack direction="row" spacing={2} className="my-2 mb-2">
                            <Typography
                                variant="h6"
                                component="div"
                                sx={{ flexGrow: 1 }}
                            ></Typography>
                        </Stack>
                        <Box height={10} />
                        <TableContainer>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell
                                            align="left"
                                            style={{ minWidth: "100px", fontFamily: "Poppins" }}
                                        >
                                            Name
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            style={{ minWidth: "100px", fontFamily: "Poppins" }}
                                        >
                                            Phone
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            style={{ minWidth: "100px", fontFamily: "Poppins" }}
                                        >
                                            Email
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            style={{ minWidth: "100px", fontFamily: "Poppins" }}
                                        >
                                            Address
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            style={{ minWidth: "100px", fontFamily: "Poppins" }}
                                        >
                                            Occupation
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            style={{ minWidth: "100px", fontFamily: "Poppins" }}
                                        >
                                            Message
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            style={{ minWidth: "100px", fontFamily: "Poppins" }}
                                        >
                                            Added
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row) => {
                                            return (
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    tabIndex={-1}
                                                    key={row.productID}
                                                    style={{ fontFamily: "Poppins" }}
                                                >
                                                    <TableCell align="left">{row.name}</TableCell>
                                                    <TableCell
                                                        align="left"
                                                        style={{
                                                            fontFamily: "Poppins",
                                                            fontWeight: "400",
                                                        }}
                                                    >
                                                        {row.phone}
                                                    </TableCell>
                                                    <TableCell
                                                        align="left"
                                                        style={{
                                                            fontFamily: "Poppins",
                                                            fontWeight: "400",
                                                        }}
                                                    >
                                                        {row.email}
                                                    </TableCell>
                                                    <TableCell
                                                        align="left"
                                                        style={{
                                                            fontFamily: "Poppins",
                                                            fontWeight: "400",
                                                        }}
                                                    >
                                                        {row.address}
                                                    </TableCell>
                                                    <TableCell
                                                        align="left"
                                                        style={{
                                                            fontFamily: "Poppins",
                                                            fontWeight: "400",
                                                        }}
                                                    >
                                                        {row.occupation}
                                                    </TableCell>
                                                    <TableCell
                                                        align="left"
                                                        style={{
                                                            fontFamily: "Poppins",
                                                            fontWeight: "400",
                                                        }}
                                                    >
                                                        {row.message}
                                                    </TableCell>
                                                    <TableCell align="left"
                                                        style={{
                                                            fontFamily: "Poppins",
                                                            fontWeight: "400",
                                                        }}
                                                    >
                                                        {row.added.toDate().toDateString()}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                )}
            </div>
        </div>
    );

}
export default Responses;