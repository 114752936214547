import "../styles/css/navbar.css"
import "../styles/css/contact.css"
import "../styles/css/home.css"
import {
    message,
} from "antd";
import jsonp from 'jsonp';
import { NavLink, useNavigate } from "react-router-dom";
import { FiMenu } from "react-icons/fi";
import { RiCloseLine } from "react-icons/ri";
import logo from "../styles/images/logo.png";
import { useState } from "react";
import { HashLink } from "react-router-hash-link";
import { FaFacebookF, FaTwitter, FaInstagram } from "react-icons/fa";




const Contact = () => {
    const [messageApi, contextHolder] = message.useMessage();

    const [clicked, setClicked] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [msg, setMsg] = useState("");
    const [_email, _setEmail] = useState('');

    const navigate = useNavigate();
    const handleClick = () => {
        setClicked(!clicked);
    };

    const submit = (e) => {
        e.preventDefault();
        if (name === "" || email === "" || subject === "" || msg === "") {
            alert("All fields are required")
        } else {
            const config = {
                SecureToken: "f9ff0048-c387-44ca-a2d0-8dd34d5c0af7",
                To: 'Zeinouh@yahoo.com',
                From: 'mozman7777@gmail.com',
                Subject: `${subject}`,
                Body: `New email from ${name}, email:${email}, ${msg}`
            }
            if (window.Email) {
                window.Email.send(config).then((msg) => alert("Email sent succesfully"), navigate("/"))

            }
        }
    }
    const onSubmit = e => {
        e.preventDefault();
        const url = 'https://yahoo.us21.list-manage.com/subscribe/post?u=09d4351f36feb4741ca1f5b3e&amp;id=8a8d85b1e2&amp;f_id=0084f4e6f0';
        jsonp(`${url}&EMAIL=${_email}`, { param: 'c' }, (_, data) => {
            // const { msg, result } = data
            // do something with response
        });
        messageApi.open({
            type: "success",
            content: "Response successfull",
        });
        e.target.reset();
    };
    return (
        <>
            <nav className="navbar">
                {contextHolder}
                <div className={clicked ? "container active" : "container"}>
                    <div onClick={() => navigate("/")} className="logo">
                        <img src={logo} alt="" />
                    </div>
                    <div className="nav-elements">
                        <ul>
                            <li>
                                <HashLink to="/#">Home</HashLink>
                            </li>
                            <li>
                                <NavLink to="/about-us">About Us</NavLink>
                            </li>
                            <li>
                                <HashLink to="/#volunteer">Become A Volunteer</HashLink>
                            </li>
                            <li>
                                <NavLink to="/contact">Contact</NavLink>
                            </li>
                        </ul>
                    </div>
                    <div className="donate-button">
                        <a href="https://www.paypal.com/donate/?hosted_button_id=PC8DFDXZ2SS7S">
                            <button>Donate now</button>
                        </a>
                    </div>
                </div>
                <div className="donate-button2">
                    <a href="https://www.paypal.com/donate/?hosted_button_id=PC8DFDXZ2SS7S">
                        <button>Donate now</button>
                    </a>
                </div>
                <div onClickCapture={handleClick} className="menu">
                    {clicked ? <RiCloseLine /> : <FiMenu />}
                </div>
            </nav>
            <section className="contact-intro">
                <h1>Contact Page</h1>
            </section>
            <section className="contact-p-row">
                <div className="cpr1">
                    <h1>Get in Touch With Us</h1>
                    <h2>WRITE A MESSAGE</h2>
                    <p>Thank you for reaching out to Timbuktu! We appreciate your interest and value the opportunity to connect with you. Whether you have questions, feedback, or inquiries, we're here to assist you. Please use the contact information below to get in touch with the relevant department</p>
                </div>
                <div className="cpr2">
                    <form onSubmit={submit}>
                        <div className="rw1">
                            <input type="text" placeholder="Your Name" onChange={(e) => setName(e.target.value)} value={name} name="name" />
                            <input type="text" placeholder="Your Email" onChange={(e) => setEmail(e.target.value)} value={email} name="email" />
                        </div>
                        <input type="text" placeholder="Your Subject" onChange={(e) => setSubject(e.target.value)} value={subject} name="subject" />
                        <textarea cols="80" rows="5" placeholder="Your Message" onChange={(e) => setMsg(e.target.value)} value={msg} name="message" />
                        <div className="s">
                            <input type="submit" value="send" className="btn" />
                        </div>
                    </form>
                </div>
            </section>
            <section className="contact-sec-2">
                <div className="c-card1">
                    <h1>About</h1>
                    <h2>We appreciate your interest and value the opportunity to connect with you</h2>
                </div>
                <div className="c-card2">
                    <h1>Address</h1>
                    <h2>1385 Lucile Avenue SE, Marietta GA 30067</h2>
                </div>
                <div className="c-card3">
                    <h1>Contact</h1>
                    <h2>zeinouh@yahoo.com</h2>
                    <h3>404-519-0340</h3>
                </div>
            </section>
            <form onSubmit={onSubmit}>
                <section className="newsletter">
                    <h1>Newsletter</h1>
                    <h2>Stay updated</h2>
                    <div className="input-wrap">
                        <input type="email"
                            placeholder="Enter E-Mail Address"
                            name="EMAIL"
                            required
                            onChange={(e) => _setEmail(e.target.value)} />
                        <button type="submit">
                            subscribe
                        </button>
                    </div>
                </section>
            </form>
            <footer>
                <div className="footer-row">
                    <div className="footer-col">
                        <h1>Pages</h1>
                        <h2><a href="">Home</a></h2>
                        <h2><a href="">About</a></h2>
                        <h2><a href="">Become a volunteer</a></h2>
                        <h2><a href="">Contact</a></h2>
                        <h2><a href="">Donate</a></h2>
                    </div>
                    <div className="footer-col">
                        <h1>Links</h1>
                        <h2><a href="">Services</a></h2>
                        <h2><a href="">Donate</a></h2>
                        <h2><a href="">Newsletter</a></h2>
                        <h2><a href="">About</a></h2>

                    </div>
                    <div className="footer-col">
                        <h1>Explore</h1>
                        <h2><a href="">Home</a></h2>
                        <h2><a href="">About</a></h2>
                        <h2><a href="">Become a volunteer</a></h2>
                        <h2><a href="">Contact</a></h2>
                        <h2><a href="">Donate</a></h2>
                    </div>
                    <div className="footer-col">
                        <h1>Contact</h1>
                        <h2>1385 Lucile Avenue SE, Marietta GA 30067</h2>
                        <h2>info@timbuktubamba.org</h2>
                        <h2>404-519-0340</h2>
                        <div className="footer-col-row">
                            <a href="https://www.facebook.com/timbuktubambaorg/" target="_blank" rel="noopener noreferrer">
                            <div className="footer-card1">
                                <FaFacebookF className="f-icon" />
                            </div>
                            </a>
                            <a href="https://www.instagram.com/timbuktubambaorg/" target="_blank" rel="noopener noreferrer">
                            <div className="footer-card3">
                                <FaInstagram className="f-icon" />

                            </div>
                            </a>
                            <a href="https://twitter.com/timbukbambaOrg" target="_blank" rel="noopener noreferrer">
                            <div className="footer-card3">
                                <FaTwitter className="f-icon" />

                            </div>
                            </a>
                        </div>
                    </div>
                </div>
                <hr />
                <h5>Copyright - 2023 - Timbuktu Bamba - All rights reserved. Designed by Mozman.</h5>
            </footer>
        </>
    );
}

export default Contact;