import './App.css';
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import Home from './pages/home';
import About from './pages/about';
import Contact from './pages/contact';
import Responses from './pages/responses';

function App() {
  return (
    <>
    <Router>
      <Routes>
      <Route path="/" element={<Home/>} />
      <Route path="/about-us" element={<About/>} />
      <Route path="/contact" element={<Contact/>} />
      <Route path="/responses" element={<Responses/>} />
      </Routes>
    </Router>
    </>
  );
}

export default App;
